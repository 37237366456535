import React, { ReactElement } from "react";

export default function BreastCancer(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 100 100"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="100"
                height="100"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H100V100H0z"></path>
            </mask>
            <g clipPath="url(#clip0_2_3076)">
                <path
                    fill="#B3B3B3"
                    d="M12 54.852V44.326c.206-.73.454-1.455.59-2.197 3.962-19.067 21.89-32.096 41.117-29.875 16.071 1.855 29.785 14.436 32.61 29.91 3.07 16.834-4.605 33.067-19.563 40.642-3.756 1.903-8.007 2.834-12.027 4.212H44.203a8.2 8.2 0 00-1.333-.519c-14.822-3.045-24.544-11.821-29.36-26.075-.614-1.832-1.015-3.723-1.51-5.572zm3.307-5.425a33.913 33.913 0 009.812 24.209 33.972 33.972 0 0024.119 10.078 34.018 34.018 0 0024.254-9.741 33.93 33.93 0 0010.16-24.063 33.905 33.905 0 00-9.824-24.35 33.968 33.968 0 00-24.272-10.061 33.963 33.963 0 00-24.205 9.825 33.902 33.902 0 00-10.043 24.097v.006z"
                ></path>
                <path
                    fill="#B3B3B3"
                    d="M49.556 66.043a16.298 16.298 0 01-11.726-4.704A16.269 16.269 0 0133 49.675a16.244 16.244 0 014.711-11.713 16.273 16.273 0 0111.68-4.815 16.285 16.285 0 0111.731 4.695 16.257 16.257 0 014.83 11.668 16.256 16.256 0 01-4.714 11.715 16.283 16.283 0 01-11.682 4.819zm-.189-30.04a13.366 13.366 0 00-9.615 3.948 13.34 13.34 0 00-3.868 9.64 13.344 13.344 0 003.913 9.638 13.37 13.37 0 009.647 3.91 13.394 13.394 0 009.78-3.959 13.368 13.368 0 003.869-9.807 13.318 13.318 0 00-4.028-9.59 13.345 13.345 0 00-9.698-3.78z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M47.269 26.832v-4.018h4.073c1.203 4.283.655 4.872-4.073 4.018zM72.13 47.365h4.128v4.37H72.13v-4.37zM57.733 23.751l4.198 1.726-1.71 4.188-4.162-1.703 1.674-4.211zM69.419 38.877l4.162-1.714 1.769 4.123-4.127 1.767c-.601-1.396-1.15-2.668-1.804-4.176zM69.973 32.221l-2.86 3.098-3.047-3.622 3.266-2.662 2.641 3.186z"
                ></path>
                <path
                    fill="#B3B3B3"
                    d="M57.15 49.55a7.487 7.487 0 01-4.758 7.144 7.508 7.508 0 01-2.983.513 7.494 7.494 0 01-7.122-4.814 7.474 7.474 0 01-.484-2.99 7.47 7.47 0 014.688-6.97 7.487 7.487 0 012.906-.54 7.488 7.488 0 017.213 4.676 7.47 7.47 0 01.54 2.981zm-3.496-.041c-.183-2.727-1.634-4.159-4.346-4.088-2.529.065-3.932 1.484-3.98 4-.052 2.703 1.363 4.246 4.128 4.27 2.765.023 4.068-1.484 4.198-4.14v-.042z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_2_3076">
                    <path
                        fill="#fff"
                        d="M0 0H75V75H0z"
                        transform="translate(12 12)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}
