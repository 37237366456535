import React, { ReactElement } from "react";
import colors from "@styles/variables/compareCRMPricesVariables.module.scss";

import Leukemia from "../icons/Leukemia";
import Lymphoma from "../icons/Lymphoma";
import Myeloma from "../icons/Myeloma";
import Parkinson from "../icons/Parkinson";
import BladderCancer from "../icons/BladderCancer";
import KidneyCancer from "../icons/KidneyCancer";
import EsophagealCancer from "../icons/EsophagealCancer";
import LungCancer from "../icons/LungCancer";
import MyelodysplasticSyndrome from "../icons/MyelodysplasticSyndrome";
import BreastCancer from "../icons/BreastCancer";
import FeatureList from "@components/shared/featureList";

const illnesses = [
    {
        title: "Leukemia",
        icon: <Leukemia />,
    },
    {
        title: "Non-Hodgkin's Lymphoma",
        icon: <Lymphoma />,
    },
    {
        title: "Multiple Myeloma",
        icon: <Myeloma />,
    },
    {
        title: "Parkinsons Disease",
        icon: <Parkinson />,
    },
    {
        title: "Bladder Cancer",
        icon: <BladderCancer />,
    },
    {
        title: "Kidney Cancer",
        icon: <KidneyCancer />,
    },
    {
        title: "Esophageal Cancer",
        icon: <EsophagealCancer />,
    },
    {
        title: "Lung Cancer",
        icon: <LungCancer />,
    },
    {
        title: "Myelodysplastic Syndrome",
        icon: <MyelodysplasticSyndrome />,
    },
    {
        title: "Breast Cancer",
        icon: <BreastCancer />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={illnesses}
            mainTitle="Eligibility for Compensation"
            mainDescription="If you lived at Camp Lejeune for at least 30 days between 1953 and 1987 and have been diagnosed with any of the following serious illnesses, you may be eligible for compensation:"
            classes={{
                mainSectionClasses: "mt-10 flex flex-col items-center lg:mt-16",
                mainTitleClasses:
                    "text-center font-bold text-xl lg:text-3xl mb-4",
                mainDescriptionClasses: "md:w-3/4 text-center mb-14",
                innerSectionClasses:
                    "grid grid-cols-2 md:grid-cols-5 justify-center",
                oneBoxClasses: "text-center mb-12 w-full",
                titleClasses: "font-medium  mt-6",
                iconClasses: "children:mx-auto ",
            }}
            colors={{
                mainTitleColor: colors.whyUsTitle,
            }}
        />
    );
}
