import React, { ReactElement } from "react";

export default function MyelodysplasticSyndrome(): ReactElement {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="none"
            viewBox="0 0 100 100"
        >
            <g>
                <path
                    fill="#B3B3B3"
                    d="M78.938 61.896a1.631 1.631 0 012.188-.719c1.581.8 3.06 1.883 4.397 3.22a1.628 1.628 0 11-2.302 2.303c-1.109-1.108-2.275-1.964-3.565-2.615a1.63 1.63 0 01-.719-2.189z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M54.393 45.937a1.628 1.628 0 01-1.151-2.78l.813-.81a1.627 1.627 0 112.299 2.305l-.81.809a1.627 1.627 0 01-1.15.476zM59.949 51.329a1.628 1.628 0 010 2.302L53.35 60.23a1.621 1.621 0 01-1.15.477 1.628 1.628 0 01-1.151-2.78l6.596-6.597a1.629 1.629 0 012.303 0zM69.232 69.512l-2.814 2.814a1.622 1.622 0 01-1.15.477 1.628 1.628 0 01-1.15-2.78l2.812-2.813 3.103-3.103a1.628 1.628 0 112.302 2.302l-3.103 3.103zM26.087 26.366l-6 5.998a1.621 1.621 0 01-1.15.477 1.628 1.628 0 01-1.151-2.78l5.998-5.997 1.11-1.11a1.628 1.628 0 012.3 2.304l-1.107 1.108zM32.083 20.368l-1.335 1.335a1.62 1.62 0 01-1.151.477 1.628 1.628 0 01-1.151-2.78l1.335-1.334a1.628 1.628 0 112.302 2.302zM32.488 25.959a1.628 1.628 0 112.302 2.302l-3.405 3.404-3.404 3.405a1.621 1.621 0 01-1.151.477 1.628 1.628 0 01-1.151-2.78l3.404-3.404 3.405-3.404z"
                ></path>
                <path
                    fill="#B3B3B3"
                    d="M15.5 32.578c5.474 5.476 12.373 4.402 19.744 2.947 1.452-7.349 2.538-14.262-2.946-19.745a1.628 1.628 0 112.303-2.303c6.31 6.31 5.517 13.883 4.094 21.368 7.484-1.424 15.055-2.218 21.367 4.093 6.312 6.311 5.518 13.885 4.093 21.37 4.013-.762 8.09-1.35 11.983-.722a1.628 1.628 0 11-.517 3.215c-3.775-.606-7.849.107-12.146.956-1.452 7.348-2.537 14.261 2.946 19.743a1.628 1.628 0 11-2.302 2.303c-6.302-6.302-5.52-13.864-4.092-21.368-2.857.544-5.725.997-8.533.997-4.548 0-8.934-1.186-12.835-5.089-2.965-2.963-4.595-6.478-4.985-10.745a1.628 1.628 0 011.473-1.77 1.62 1.62 0 011.77 1.472c.323 3.527 1.61 6.304 4.045 8.74 5.476 5.476 12.377 4.4 19.744 2.946 1.452-7.348 2.538-14.26-2.946-19.743-5.476-5.477-12.375-4.401-19.744-2.946-.42 2.125-.823 4.287-1.03 6.352a1.63 1.63 0 01-1.783 1.459 1.629 1.629 0 01-1.458-1.783c.177-1.772.485-3.57.824-5.35-2.858.545-5.727.998-8.535.998-4.548 0-8.934-1.187-12.836-5.089a1.628 1.628 0 010-2.302 1.627 1.627 0 012.301-.002v-.002z"
                ></path>
                <path
                    fill="#C1312C"
                    d="M40.182 47.56a1.628 1.628 0 01-1.151-2.78l5.47-5.469a1.628 1.628 0 112.302 2.303l-5.47 5.47a1.626 1.626 0 01-1.151.476zM44.37 56.634a1.621 1.621 0 01-1.15.477 1.628 1.628 0 01-1.151-2.78l7.47-7.47a1.628 1.628 0 112.303 2.302l-7.471 7.471zM66.497 78.752l.906-.905a1.627 1.627 0 112.3 2.305l-.9.9a1.625 1.625 0 01-1.153.48 1.631 1.631 0 01-1.152-2.78zM72.485 72.765l5.987-5.987a1.628 1.628 0 112.302 2.303l-5.987 5.986-1.38 1.381c-.318.32-.734.478-1.152.478a1.628 1.628 0 01-1.152-2.779l1.382-1.382z"
                ></path>
            </g>
        </svg>
    );
}
